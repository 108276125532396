<template>
  <div>
    <section id="services">
      <Services />
    </section>
  </div>
</template>

<script>
import Services from '@/components/Services.vue';

export default {
  components: {
    Services,
  },
};
</script>

<style scoped>
section {
  padding: 2rem 2rem 2rem 2rem;
}
#services { 
  background-color: #3C3B39; 
}
</style>
