<template>
  <section id="contact">
    <div class="background">
      <div class="overlay">
        <div class="form-container">
          <h2>Kontakt</h2>
          <form @submit.prevent="submitForm">
            <div class="form-group">
              <label for="name">Name:</label>
              <input type="text" id="name" v-model="form.name" required placeholder="Vollständige Name">
            </div>
            <div class="form-group">
              <label for="email">Email:</label>
              <input type="email" id="email" v-model="form.email" required placeholder="Hier geben Sie Ihre E-Mail-Adresse ein">
            </div>
            <div class="form-group">
              <label for="phone">Telefon/Handy:</label>
              <input type="tel" id="phone" v-model="form.phone" placeholder="Hier geben Sie Ihre Telefonnummer ein">
            </div>
            <div class="form-group">
              <label for="message">Nachricht:</label>
              <textarea id="message" v-model="form.message" required placeholder="Hier schreiben Sie Ihre Nachricht"></textarea>
            </div>
            <p>*Durch Betätigen der Schaltfläche "Absenden" stimmen Sie zu, dass das Orchidee Beauty Studio Ihre Kontaktdaten gemäß unserer <a href="/privacy-policy">Datenschutzrichtlinie</a> zur Bearbeitung dieser Anfrage verwendet.</p>
            <button type="submit">SENDEN</button>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ContactView',
  data() {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        message: ''
      }
    };
  },
  methods: {
    async submitForm() {
      try {
        const response = await this.$axios.post('/contact', this.form);
        alert(response.data);
        
        this.resetForm();
      } catch (error) {
        alert('Error sending message: ' + error);
      }
    },
    resetForm() {
      this.form.name = '';
      this.form.email = '';
      this.form.phone = '';
      this.form.message = '';
    }
  }
};
</script>

<style scoped>
#contact {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}

form {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

form p {
  color: #a6a5a5;
  text-align: center;
  padding-bottom: 15px;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('@/assets/contact_img2.png') no-repeat center center;
  background-size: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-container {
  background: rgba(255, 255, 255, 0.123);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 700px;
  max-height: 670px;
  margin: 30px;
}

h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #E1C186;
  font-weight: 700;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: #D3A166;
}

input, textarea {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #717171;
  border-radius: 4px;
  line-height: 1.5;
  color: #fff;
}

button {
  background-color: #E1C186;
  font-weight: bold;
}

button:hover {
  background-color: #D3A166;
}

/* Media query for small screens */
@media (max-width: 600px) {
  .form-container {
    padding: 1rem;
    max-width: 90%;
    margin-top: 50px;
  }

  input, textarea {
    padding: 0.6rem;
    font-size: 0.9rem;
  }

  h2 {
    font-size: 1.5rem;
  }
  
  form p {
    font-size: 0.9rem;
  }
}
</style>
