<template>
  <div>
    <section id="booking">
      <Booking />
    </section>
  </div>
</template>

<script>
import Booking from '../components/Booking.vue';

export default {
  components: {
    Booking,
  }
};
</script>

<style scoped>
section {
  padding: 4rem 2rem 2rem 2rem;
}

#booking {
  display: flex;
  justify-content: center;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('@/assets/contact_img2.png') no-repeat center center;
  background-size: cover;
  min-height: 100vh;
}

#booking::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

#booking > * {
  position: relative;
  z-index: 2;
}
</style>
