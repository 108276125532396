<template>
  <footer>
    <div class="footer-content">
      <div class="contact-info">
        <p>Orchidee Beauty Studio</p>
        <p>
          <a href="https://maps.app.goo.gl/AN4L2gSpq3Am5ZmS7" target="_blank" rel="noopener noreferrer">
            <i class="fas fa-map-marker-alt"></i> Hauptstraße 47, Iffezheim, Germany
          </a>
        </p>
        <p>
          <a href="mailto:Orchideebeautystudio@gmail.com"><i class="fas fa-envelope"></i> Orchideebeautystudio@gmail.com</a>
        </p>
        <p>
          <a href="tel:+4915259503640"><i class="fas fa-phone-alt"></i> +49 1525 9503640</a>
        </p>
        <p class="policy"><a href="/privacy-policy">Datenschutzrichtlinie</a></p>
      </div>
      <div class="social-media">
        <a href="https://www.facebook.com/JoannaWetzelBeautyStylist" target="_blank" rel="noopener noreferrer" class="social-icon">
          <i class="fab fa-facebook-f"></i>
        </a>
        <a href="https://instagram.com/Orchideebeautystudio" target="_blank" rel="noopener noreferrer" class="social-icon">
          <i class="fab fa-instagram"></i>
        </a>
      </div>
    </div>
    <p class="footer-name">Copyright © 2024 Justyna Sadlo - all rights reserved</p>
  </footer>
</template>

<script>
export default {
  name: 'FooterView',
};
</script>

<style scoped>
footer {
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.921);
  color: #fff;
  text-align: center;
  box-shadow: 0 4px 8px rgba(24, 22, 22, 0.9);
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
}

.contact-info p {
  margin: 0.5rem 0;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-info a {
  color: #fff;
  text-decoration: none;
  transition: transform 0.6s, color 0.3s;
}

.contact-info a:hover {
  transform: scale(1.2);
}

.social-media a {
  color: #fff;
  margin: 0 0.5rem;
  text-decoration: none;
  font-size: 2rem;
  transition: color 0.3s;
}

.social-media a:hover {
  transform: scale(1.2);
  transition: transform 0.6s, color 0.3s;
}

.social-icon {
  display: inline-block;
  padding: 0.5rem;
}

.social-icon i {
  display: block;
}

.footer-name {
  color: #787575;
  position: absolute; 
  font-size: 8px;
}

@media (max-width: 600px) {
  .contact-info p {
    font-size: 15px;
  }
}

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');
</style>
