<template>
    <div v-if="!accepted" class="cookie-consent-overlay">
      <div class="cookie-consent">
        <p>
          Our website Orchidee Beauty Studio uses cookies. If you continue to use our services, we will assume that you agree to the use of cookies. More information about cookies and the options to refuse them can be found <a href="/privacy-policy">here</a>.
        </p>
        <div class="cookie-consent-buttons">
          <button @click="acceptCookies">Accept All</button>
          <button @click="showPrivacyPolicy">Lern more</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        accepted: localStorage.getItem('cookiesAccepted') === 'true'
      };
    },
    methods: {
      acceptCookies() {
        localStorage.setItem('cookiesAccepted', 'true');
        this.accepted = true;
      },
      showPrivacyPolicy() {
        this.$router.push('/privacy-policy');
      }
    }
  };
  </script>
  
  <style scoped>
  .cookie-consent-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .cookie-consent {
    background: #ffffffb1;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    padding: 20px;
    max-width: 90%;
    width: 400px;
    text-align: center;
  }
  
  .cookie-consent p {
    margin: 0 0 20px;
    font-size: 14px;
  }
  
  .cookie-consent a {
    color: #D3A166;
    text-decoration: underline;
  }
  
  .cookie-consent-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .cookie-consent button {
    background-color: #E1C186;
    padding: 10px 20px;
    border: 1px solid #999898;
    font-weight: bolder;
  }
  
  .cookie-consent button:hover {
    background-color: #D3A166;
  }
  
  .cookie-consent button:nth-child(2) {
    background-color: #6c757d;
  }
  
  .cookie-consent button:nth-child(2):hover {
    background-color: #5a6268;
  }
  </style>
  