<template>
  <div class="admin-welcome">
    <h1>Manage Welcome</h1>
    <div class="welcome-info">
      <input v-model="welcomeTitle" placeholder="Welcome Title" />
      <textarea v-model="welcomeBody" placeholder="Welcome Body"></textarea>
      <button @click="saveWelcomeText">Save</button>
      <button @click="confirmReset">Delete</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      welcomeTitle: '',
      welcomeBody: '',
      apiUrl: process.env.VUE_APP_API_BASE_URL
    };
  },
  methods: {
    async fetchWelcome() {
      try {
        const response = await axios.get(`${this.apiUrl}/welcome`);
        const welcome = response.data;
        if (welcome) {
          this.welcomeTitle = welcome.title || '';
          this.welcomeBody = welcome.body || '';
        }
      } catch (error) {
        console.error('Failed to fetch welcome text:', error);
      }
    },
    async saveWelcomeText() {
      try {
        const response = await axios.post(`${this.apiUrl}/welcome`, {
          title: this.welcomeTitle,
          body: this.welcomeBody,
        });
        console.log('Saved welcome data:', response.data);
        alert('Welcome text has been successfully saved.');
        this.fetchWelcome();
      } catch (error) {
        console.error('Failed to save welcome text:', error);
      }
    },
    async confirmReset() {
      if (confirm('Are you sure you want to delete the welcome text?')) {
        try {
          const response = await axios.delete(`${this.apiUrl}/welcome`);
          console.log('Deleted welcome data:', response.data);
          alert('Welcome text has been successfully deleted.');
          this.resetWelcomeForm(); 
        } catch (error) {
          console.error('Failed to delete welcome text:', error);
        }
      }
    },
    resetWelcomeForm() {
      this.welcomeTitle = '';
      this.welcomeBody = '';
    }
  },
  mounted() {
    this.fetchWelcome();
  }
};
</script>

<style scoped>
  h1 {
  text-align: center;
  padding: 20px;
  color: #fffffff5;
  }

  h3 {
    padding: 20px;
    text-align: center;
    color: #fffffff5;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }

  th, td {
    border: 1px solid #717171;
    padding: 8px;
    color: #D3A166;
  }

  th {
    background-color: #3C3B39;
    text-align: center;
    text-transform: uppercase;
    color: #fffffff5;
  }
    
  button {
    padding: 5px;
    border: none;
    border-radius: 3px;
    background-color: #E1C186;
    color: rgb(0, 0, 0);
    cursor: pointer;
    font-weight: bolder;
    text-transform: uppercase;
  }

  button:hover {
    background-color: #D3A166;
  }

  input, select {
    padding: 8px;
    border: 1px solid #717171;
    border-radius: 3px;
    margin: 5px;
    color: #D3A166;
    text-align: center;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    margin: 5px 0;
  }

  textarea {
    height: 200px;
    border: 1px solid #717171;
    border-radius: 3px;
    text-align: center;
    margin: 5px;
    color: #D3A166;
  }

  .manage-welcome {
  margin-top: 20px;
  text-align: -webkit-center;
  }

  .welcome-info {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
</style>
  