<template>
  <div class="login-container">
    <div class="login-box">
      <h1>Login</h1>
      <form @submit.prevent="login">
        <label for="password">Password:</label>
        <input type="password" v-model="password" id="password" />
        <button type="submit">LOGIN</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      password: '',
      apiUrl: process.env.VUE_APP_API_BASE_URL
    };
  },
  methods: {
    async login() {
      try {
        const response = await axios.post(`${this.apiUrl}/admin/login`, {
          password: this.password
        });
        if (response.status === 200) {
          localStorage.setItem('isAuthenticated', 'true');
          this.$router.push('/admin');
        }
      } catch (error) {
        alert('Incorrect password');
      }
    }
  }
};
</script>
  
<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background: url('@/assets/contact_img2.png') no-repeat center center;
  background-size: cover;
}

.login-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.login-box {
  position: relative;
  background: rgba(255, 255, 255, 0.123);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 300px;
  max-width: 90%;
  z-index: 2; 
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 5px;
  color: #D3A166;
}

input {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #717171;
  border-radius: 4px;
}

button {
  padding: 10px;
  background-color: #E1C186;
  color: #000000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}
  
button:hover {
  background-color: #D3A166;
}
</style>