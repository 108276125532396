<template>
  <section id="welcome" :style="{ backgroundImage: 'url(' + currentSlide + ')' }">
    <div class="content">
      <div class="text-column fade-in">
        <h1>{{ welcomeText.title }}</h1>
        <p>{{ welcomeText.body }}</p>
      </div>
      <div class="image-column fade-in">
        <img :src="ownerImage" alt="Orchidee Beauty Studio Owner" />
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  name: 'WelcomeView',
  data() {
    return {
      ownerImage: require('@/assets/logoLarge.png'),
      welcomeText: {
        title: 'Welcome to Orchidee Beauty Studio Iffezheim',
        body: ''
      },
      slideImages: [
        require('@/assets/salon3.jpeg'),
        require('@/assets/salon2.jpeg'),
        require('@/assets/salon4.jpeg'),
        require('@/assets/salon1.jpeg')
      ],
      currentSlideIndex: 0,
      currentSlide: require('@/assets/salon3.jpeg'),
      apiUrl: process.env.VUE_APP_API_BASE_URL
    };
  },
  async created() {
    await this.fetchWelcomeText();
    this.startSlideShow();
  },
  methods: {
    async fetchWelcomeText() {
      try {
        const response = await axios.get(`${this.apiUrl}/welcome`);
        if (response.data) {
          this.welcomeText = {
            title: response.data.title || 'Default Welcome Title',
            body: response.data.body || 'Default Welcome Body'
          };
        } else {
          console.warn('No welcome text found, using default values');
        }
      } catch (error) {
        console.error('Error fetching welcome text:', error);
      }
    },
    startSlideShow() {
      setInterval(() => {
        this.currentSlideIndex = (this.currentSlideIndex + 1) % this.slideImages.length;
        this.currentSlide = this.slideImages[this.currentSlideIndex];
      }, 5000);
    }
  },
  mounted() {
    setTimeout(() => {
      document.querySelectorAll('.fade-in').forEach(el => {
        el.classList.add('visible');
      });
    }, 300);
  }
};
</script>

<style scoped>
#welcome {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-size: cover;
  background-position: center;
  color: #E1C186;
  overflow: hidden;
  min-height: 70vh;
  transition: background-image 1s ease-in-out;
}

#welcome::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.content {
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  position: relative;
  z-index: 2;
  margin-top: auto;
}

.text-column, .image-column {
  flex: 1;
  margin: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}

.text-column.visible, .image-column.visible {
  opacity: 1;
}

h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #E1C186;
}

.text-column h2 {
  margin-bottom: 10px;
}

.text-column p {
  text-align: center;
  font-size: 20px;
}

.image-column img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

@media (max-width: 900px) {
  .content {
    flex-direction: column-reverse;
  }

  .text-column, .image-column {
  margin: 10px;
  }

  .text-column p {
  font-size: 17px;
}

  h1 {
    font-size: 20px;
  }
}
</style>
