<template>
    <div class="privacy-policy">
        <h1>Privacy Policy</h1>

        <h2>1. An overview of data protection</h2>
        <h3>General information</h3>
        <p>The following information will provide you with an easy-to-navigate overview of what will happen with your personal data when you visit our website. The term “personal data” comprises all data that can be used to personally identify you. For detailed information about the subject matter of data protection, please consult our Data Protection Declaration, which we have included beneath this copy.</p>

        <h3>Data recording on our website</h3>
        <h4>Who is the responsible party for the recording of data on this website (i.e., the “controller”)?</h4>
        <p>The data on this website is processed by the operator of the website, whose contact information is available under section “Information Required by Law” on this website.</p>

        <h4>How do we record your data?</h4>
        <p>We collect your data as a result of your sharing of your data with us. This may, for instance, be information you enter into our contact form.</p>
        <p>Other data shall be recorded by our IT systems automatically or after you consent to its recording during your website visit. This data comprises primarily technical information (e.g., web browser, operating system, or time the site was accessed). This information is recorded automatically when you access our website.</p>

        <h4>What are the purposes we use your data for?</h4>
        <p>A portion of the information is generated to guarantee the error-free provision of the website. Other data may be used to analyze your user patterns.</p>

        <h4>What rights do you have as far as your information is concerned?</h4>
        <p>You have the right to receive information about the source, recipients, and purposes of your archived personal data at any time without having to pay a fee for such disclosures. You also have the right to demand that your data be rectified or eradicated. If you have consented to data processing, you have the option to revoke this consent at any time, which shall affect all future data processing. Moreover, you have the right to demand that the processing of your data be restricted under certain circumstances. Furthermore, you have the right to log a complaint with the competent supervising agency.</p>
        <p>Please do not hesitate to contact us at any time if you have questions about this or any other data protection-related issues.</p>

        <h3>Analysis tools and tools provided by third parties</h3>
        <p>There is a possibility that your browsing patterns will be statistically analyzed when you visit our website. Such analyses are performed primarily with cookies and with what we refer to as analysis programs.</p>

        <h2>2. Hosting</h2>
        <h3>External hosting</h3>
        <p>This website is hosted by an external service provider (host). Personal data collected on this website is stored on the servers of the host. This may include, but is not limited to, IP addresses, contact requests, metadata and communications, contract information, contact information, names, web page access, and other data generated through a website.</p>
        <p>The host is used for the purpose of fulfilling the contract with our potential and existing customers (Art. 6(1)(b) GDPR) and in the interest of secure, fast, and efficient provision of our online services by a professional provider (Art. 6(1)(f) GDPR).</p>
        <p>Our host will only process your data to the extent necessary to fulfill its performance obligations and to follow our instructions with respect to such data.</p>

        <h2>3. General information and mandatory information</h2>
        <h3>Data protection</h3>
        <p>The operators of this website and its pages take the protection of your personal data very seriously. Hence, we handle your personal data as confidential information and in compliance with the statutory data protection regulations and this Data Protection Declaration.</p>
        <p>Whenever you use this website, a variety of personal information will be collected. Personal data comprises data that can be used to personally identify you. This Data Protection Declaration explains which data we collect as well as the purposes we use this data for. It also explains how, and for which purpose the information is collected.</p>
        <p>We herewith advise you that the transmission of data via the Internet (i.e., through e-mail communications) may be prone to security gaps. It is not possible to completely protect data against third-party access.</p>

        <h3>Information about the responsible party (referred to as the “controller” in the GDPR)</h3>
        <p>The data processing controller on this website is:</p>
        <p>
            Orchidee Beauty Studio<br>
            Hauptstraße 47<br>
            76473 Iffezheim<br>
            Phone: +49 1525 9503640<br>
            Email: ira.wade@gmail.com<br>
        </p>
        <p>The controller is the natural person or legal entity that single-handedly or jointly with others makes decisions as to the purposes of and resources for the processing of personal data (e.g., names, email addresses, etc.).</p>

        <h3>Revocation of your consent to the processing of data</h3>
        <p>A wide range of data processing transactions are possible only subject to your express consent. You can also revoke at any time any consent you have already given us. To do so, all you are required to do is send us an informal notification via e-mail. This shall be without prejudice to the lawfulness of any data collection that occurred prior to your revocation.</p>

        <h3>Right to object to the collection of data in special cases; right to object to direct advertising (Art. 21 GDPR)</h3>
        <p>IN THE EVENT THAT DATA IS PROCESSED ON THE BASIS OF ART. 6(1)(E) OR (F) GDPR, YOU HAVE THE RIGHT TO AT ANY TIME OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA BASED ON GROUNDS ARISING FROM YOUR UNIQUE SITUATION. THIS ALSO APPLIES TO ANY PROFILING BASED ON THESE PROVISIONS. TO DETERMINE THE LEGAL BASIS ON WHICH ANY PROCESSING OF DATA IS BASED, PLEASE CONSULT THIS DATA PROTECTION DECLARATION. IF YOU LOG AN OBJECTION, WE WILL NO LONGER PROCESS YOUR AFFECTED PERSONAL DATA, UNLESS WE ARE IN A POSITION TO PRESENT COMPELLING PROTECTION WORTHY GROUNDS FOR THE PROCESSING OF YOUR DATA, THAT OUTWEIGH YOUR INTERESTS, RIGHTS AND FREEDOMS OR IF THE PURPOSE OF THE PROCESSING IS THE CLAIMING, EXERCISING OR DEFENSE OF LEGAL ENTITLEMENTS (OBJECTION PURSUANT TO ART. 21(1) GDPR).</p>
        <p>IF YOUR PERSONAL DATA IS BEING PROCESSED IN ORDER TO ENGAGE IN DIRECT ADVERTISING, YOU HAVE THE RIGHT TO OBJECT TO THE PROCESSING OF YOUR AFFECTED PERSONAL DATA FOR THE PURPOSES OF SUCH ADVERTISING AT ANY TIME. THIS ALSO APPLIES TO PROFILING TO THE EXTENT THAT IT IS AFFILIATED WITH SUCH DIRECT ADVERTISING. IF YOU OBJECT, YOUR PERSONAL DATA WILL SUBSEQUENTLY NO LONGER BE USED FOR DIRECT ADVERTISING PURPOSES (OBJECTION PURSUANT TO ART. 21(2) GDPR).</p>

        <h3>Right to log a complaint with the competent supervisory agency</h3>
        <p>In the event of violations of the GDPR, data subjects are entitled to log a complaint with a supervisory agency, in particular in the member state where they usually maintain their domicile, place of work or at the place where the alleged violation occurred. The right to log a complaint is in effect regardless of any other administrative or court proceedings available as legal recourses.</p>

        <h3>Right to data portability</h3>
        <p>You have the right to demand that we hand over any data we automatically process on the basis of your consent or in order to fulfill a contract be handed over to you or a third party in a commonly used, machine-readable format. If you should demand the direct transfer of the data to another controller, this will be done only if it is technically feasible.</p>

        <h3>SSL and/or TLS encryption</h3>
        <p>For security reasons and to protect the transmission of confidential content, such as purchase orders or inquiries you submit to us as the website operator, this website uses either an SSL or a TLS encryption program. You can recognize an encrypted connection by checking whether the address line of the browser switches from “http://” to “https://” and also by the appearance of the lock icon in the browser line.</p>
        <p>If the SSL or TLS encryption is activated, data you transmit to us cannot be read by third parties.</p>

        <h3>Information about, rectification and eradication of data</h3>
        <p>Within the scope of the applicable statutory provisions, you have the right to at any time demand information about your archived personal data, their source and recipients as well as the purpose of the processing of your data. You may also have a right to have your data rectified or eradicated. If you have questions about this subject matter or any other questions about personal data, please do not hesitate to contact us at any time.</p>

        <h3>Right to demand processing restrictions</h3>
        <p>You have the right to demand the imposition of restrictions as far as the processing of your personal data is concerned. To do so, you may contact us at any time. The right to demand restriction of processing applies in the following cases:</p>
        <ul>
        <li>In the event that you should dispute the correctness of your data archived by us, we will usually need some time to verify this claim. During the time that this investigation is ongoing, you have the right to demand that we restrict the processing of your personal data.</li>
        <li>If the processing of your personal data was/is conducted in an unlawful manner, you have the option to demand the restriction of the processing of your data in lieu of demanding the eradication of this data.</li>
        <li>If we do not need your personal data any longer and you need it to exercise, defend or claim legal entitlements, you have the right to demand the restriction of the processing of your personal data instead of its eradication.</li>
        <li>If you have raised an objection pursuant to Art. 21(1) GDPR, your rights and our rights will have to be weighed against each other. As long as it has not been determined whose interests prevail, you have the right to demand a restriction of the processing of your personal data.</li>
        </ul>
        <p>If you have restricted the processing of your personal data, these data - with the exception of their archiving - may be processed only subject to your consent or to claim, exercise or defend legal entitlements or to protect the rights of other natural persons or legal entities or for important public interest reasons cited by the European Union or a member state of the EU.</p>

        <h2>4. Recording of data on our website</h2>
        <h3>Cookies</h3>
        <p>In some instances, our website and its pages use so-called cookies. Cookies do not cause any damage to your computer and do not contain viruses. The purpose of cookies is to make our website more user-friendly, effective and more secure. Cookies are small text files that are placed on your computer and stored by your browser.</p>
        <p>Most of the cookies we use are so-called “session cookies.” They are automatically deleted after you leave our site. Other cookies will remain archived on your device until you delete them. These cookies enable us to recognize your browser the next time you visit our website.</p>
        <p>You can adjust the settings of your browser to make sure that you are notified every time cookies are placed and to enable you to accept cookies only in specific cases or to exclude the acceptance of cookies for specific situations or in general and to activate the automatic deletion of cookies when you close your browser. If you deactivate cookies, the functions of this website may be limited.</p>
        <p>Cookies that are required for the performance of the electronic communications transaction or to provide certain functions you want to use (e.g., the shopping cart function), are stored on the basis of Art. 6(1)(f) GDPR. The website operator has a legitimate interest in storing cookies to ensure the technically error-free and optimized provision of the operator's services. If a corresponding agreement has been requested (e.g., an agreement to the storage of cookies), the processing takes place exclusively on the basis of Art. 6(1)(a) GDPR; the agreement can be revoked at any time.</p>
        <p>If other cookies (e.g., cookies for the analysis of your browsing patterns) should be stored, they are addressed separately in this Data Protection Declaration.</p>

        <h3>Server log files</h3>
        <p>The provider of this website and its pages automatically collects and stores information in so-called server log files, which your browser communicates to us automatically. The information comprises:</p>
        <ul>
        <li>The type and version of browser used</li>
        <li>The used operating system</li>
        <li>Referrer URL</li>
        <li>The hostname of the accessing computer</li>
        <li>The time of the server inquiry</li>
        <li>The IP address</li>
        </ul>
        <p>This data is not merged with other data sources.</p>
        <p>This data is recorded on the basis of Art. 6(1)(f) GDPR. The operator of the website has a legitimate interest in the technically error-free depiction and the optimization of the operator's website. In order to achieve this, server log files must be recorded.</p>

        <h3>Contact form</h3>
        <p>If you submit inquiries to us via our contact form, the information provided in the contact form as well as any contact information provided therein will be stored by us in order to handle your inquiry and in the event that we have further questions. We will not share this information without your consent.</p>
        <p>The processing of these data is based on Art. 6(1)(b) GDPR, if your request is related to the execution of a contract or if it is necessary to carry out pre-contractual measures. In all other cases the processing is based on our legitimate interest in the effective processing of the requests addressed to us (Art. 6(1)(f) GDPR) or on your agreement (Art. 6(1)(a) GDPR) if this has been requested.</p>
        <p>The information you have entered into the contact form shall remain with us until you ask us to eradicate the data, revoke your consent to the archiving of data or if the purpose for which the information is being archived no longer exists (e.g., after we have concluded our response to your inquiry). This shall be without prejudice to any mandatory legal provisions - in particular retention periods.</p>

        <h3>Request by e-mail, telephone, or fax</h3>
        <p>If you contact us by e-mail, telephone or fax, your request, including all resulting personal data (name, request) will be stored and processed by us for the purpose of processing your request. We do not pass these data on without your consent.</p>
        <p>These data are processed on the basis of Art. 6(1)(b) GDPR if your inquiry is related to the fulfillment of a contract or is required for the performance of pre-contractual measures. In all other cases, the data are processed based on our legitimate interest in the effective handling of inquiries submitted to us (Art. 6(1)(f) GDPR) or on your consent (Art. 6(1)(a) GDPR) if it has been obtained.</p>
        <p>The data sent by you to us via contact requests remain with us until you request us to delete, revoke your consent to the storage or the purpose for the data storage lapses (e.g., after completion of your request). Mandatory statutory provisions - in particular statutory retention periods - remain unaffected.</p>

        <h3>Social media</h3>
        <p>We do not use social media plug-ins on our website. However, you will find links to our social media profiles, for example on Facebook, Twitter, LinkedIn, or other platforms. When you click on these links, your browser will establish a direct connection with the servers of the respective social network. This means that the respective provider will receive the information that you have visited our site with your IP address. If you click on the links while logged in to your account on the respective social network, the provider can also associate your profile with your visit to our site. Please note that we do not have any influence over the data collected and used by these social networks and therefore cannot assume any responsibility for this data processing. For more information on the purpose and scope of data collection and processing by the respective social network, please refer to the privacy policy of the respective provider.</p>

        <h2>5. Analysis tools and advertising</h2>
        <h3>Google Analytics</h3>
        <p>This website uses functions of the web analysis service Google Analytics. The provider is Google Ireland Limited (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.</p>
        <p>Google Analytics uses so-called “cookies.” These are text files that are stored on your computer and that enable an analysis of your use of the website. The information generated by the cookie about your use of this website is usually transmitted to a Google server in the United States, where it is stored.</p>
        <p>The storage of Google Analytics cookies and the use of this analysis tool are based on Art. 6(1)(f) GDPR. The website operator has a legitimate interest in analyzing user patterns to optimize both the services offered online and the operator's advertising activities. If a corresponding agreement has been requested (e.g., an agreement to the storage of cookies), the processing takes place exclusively on the basis of Art. 6(1)(a) GDPR; the agreement can be revoked at any time.</p>
        <p>
        <strong>IP anonymization</strong><br>
        We have activated the IP anonymization function on this website. As a result, your IP address will be abbreviated by Google within the member states of the European Union or in other states that have ratified the Agreement on the European Economic Area prior to its transmission to the United States. The full IP address will be transmitted to one of Google's servers in the United States and abbreviated there only in exceptional cases. On behalf of the operator of this website, Google shall use this information to analyze your use of this website, to generate reports on website activities, and to render other services to the operator of this website that are related to the use of the website and the Internet. The IP address transmitted in conjunction with Google Analytics from your browser shall not be merged with other data in Google's possession.
        </p>
        <p>
        <strong>Browser plug-in</strong><br>
        You can prevent the recording and processing of your data by Google by downloading and installing the browser plug-in available under the following link: <a href="https://tools.google.com/dlpage/gaoptout?hl=en">https://tools.google.com/dlpage/gaoptout?hl=en</a>.
        </p>
        <p>For more information about the handling of user data by Google Analytics, please consult Google's Data Privacy Declaration at: <a href="https://support.google.com/analytics/answer/6004245?hl=en">https://support.google.com/analytics/answer/6004245?hl=en</a>.</p>

        <h3>Google Analytics Remarketing</h3>
        <p>Our websites use the functions of Google Analytics Remarketing in combination with the functions of Google Ads and Google DoubleClick. The provider is Google Ireland Limited (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.</p>
        <p>This function makes it possible to link the advertising target groups created with Google Analytics Remarketing with the cross-device functions of Google Ads and Google DoubleClick. This makes it possible to display interest-based personalized advertising messages depending on your previous usage and browsing patterns on a device (e.g., mobile phone) in a manner tailored to you as well as on any of your devices (e.g., tablet or PC).</p>
        <p>If you have given the corresponding consent, Google will link your web and app browsing history to your Google Account for this purpose. As a result, any device that logs on to your Google Account can use the same personalized advertising messages.</p>
        <p>To support this feature, Google Analytics records Google-authenticated IDs of users that are temporarily linked to our Google Analytics data to define and create target groups for cross-device ad promotion.</p>
        <p>You have the option to permanently object to cross-device remarketing/targeting by deactivating personalized advertising in your Google Account; follow this link: <a href="https://www.google.com/settings/ads/onweb/">https://www.google.com/settings/ads/onweb/</a>.</p>
        <p>The aggregation of the data recorded in your Google Account shall occur exclusively based on your consent, which you may give to Google and revoke there (Art. 6(1)(a) GDPR). Data recording processes that are not merged in your Google Account (for instance because you do not have a Google Account or have objected to the merging of data) are recorded based on Art. 6(1)(f) GDPR. The website operator has a legitimate interest in analyzing anonymous user patterns for promotional purposes.</p>
        <p>For further information and the pertinent data protection regulations, please consult the Data Privacy Policies of Google at: <a href="https://policies.google.com/technologies/ads?hl=en">https://policies.google.com/technologies/ads?hl=en</a>.</p>

        <h3>Google Ads and Google Conversion Tracking</h3>
        <p>This website uses Google Ads. Google Ads is an online promotional program of Google Ireland Limited (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.</p>
        <p>In conjunction with Google Ads, we use a tool called Google Conversion Tracking. If you click on an ad posted by Google, a cookie for Conversion Tracking purposes will be placed on your computer. These cookies expire after 30 days and are not used to identify you personally. If the user visits certain pages of this website and the cookie has not yet expired, Google and we will be able to recognize that the user has clicked on an ad and has been linked to this page.</p>
        <p>A different cookie is assigned to every Google Ads customer. These cookies cannot be tracked via websites of Google Ads customers. The information obtained with the assistance of the Conversion cookie is used to generate Conversion statistics for Google Ads customers who have opted to use Conversion Tracking. The users receive the total number of users who have clicked on their ads and have been linked to a page equipped with a Conversion Tracking tag. However, they do not receive any information that would allow them to personally identify these users. If you do not want to participate in tracking, you have the option to object to this use by easily deactivating the Google Conversion Tracking cookie via your web browser under user settings. If you do this, you will not be included in the Conversion Tracking statistics.</p>
        <p>The storage of “Conversion cookies” and the use of this tracking tool are based on Art. 6(1)(f) GDPR. The website operator has a legitimate interest in analyzing user patterns to optimize both the operator's web offerings and advertising. If a corresponding agreement has been requested (e.g., an agreement to the storage of cookies), the processing takes place exclusively on the basis of Art. 6(1)(a) GDPR; the agreement can be revoked at any time.</p>
        <p>To review more detailed information about Google Ads and Google Conversion Tracking, please consult the Google Data Privacy Policies at: <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>.</p>

        <h3>Facebook Pixel</h3>
        <p>To measure conversion rates, this website uses the visitor action pixel of Facebook. The provider of this service is Facebook Ireland Limited, 4 Grand Canal Square, Dublin</p>
    </div>
</template>

<script>
export default {
name: 'PrivacyPolicy'
};
</script>

<style scoped>
.privacy-policy {
    padding: 40px;
}

h3 {
    color: rgb(97, 97, 97);
}
</style>
  