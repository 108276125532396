<template>
  <div class="admin-pricelist-manager">
    <h1>Admin Price List Manager</h1>
    <div class="pricelist-buttons">
      <button @click="addServicePriceList">New Service</button>
    </div>
    <div class="manage-pricelist">
      <div class="pricelist-box">
        <div v-for="(pricelist, index) in aboutServicePriceList" :key="index">
          <div class="pricelist-details">
            <input v-model="pricelist.name" placeholder="Service Name" />
            <input v-model="pricelist.description" placeholder="Service Description" />
            <input v-model="pricelist.price" placeholder="Service Price" />
            <input type="file" @change="event => handleServiceFileChange(index, event)" />
            <img v-if="pricelist.image" :src="pricelist.image" :alt="pricelist.name" />
            <button @click="confirmRemoveServicePriceList(index)">Delete</button>
            <button v-if="!pricelist._id" @click="saveServicePriceList(index)">Save</button>
            <button v-if="pricelist._id" @click="saveServicePriceList(index)">Update</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      aboutServicePriceList: [],
      apiUrl: process.env.VUE_APP_API_BASE_URL,
    };
  },
  methods: {
    async fetchServicePriceList() {
      try {
        const response = await axios.get(`${this.apiUrl}/pricelist`);
        this.aboutServicePriceList = response.data;
      } catch (error) {
        console.error('Failed to fetch pricelist information:', error);
      }
    },
    async saveServicePriceList(index) {
      const item = this.aboutServicePriceList[index];
      try {
        if (item._id) {
          await axios.put(`${this.apiUrl}/pricelist/${item._id}`, {
            name: item.name,
            price: item.price,
            image: item.image,
            description: item.description,
          });
        } else {
          const response = await axios.post(`${this.apiUrl}/pricelist`, {
            name: item.name,
            price: item.price,
            image: item.image,
            description: item.description,
          });
          item._id = response.data._id;
        }
        alert('Pricelist information saved successfully!');
        this.fetchServicePriceList();
      } catch (error) {
        console.error('Failed to save pricelist information:', error);
      }
    },
    addServicePriceList() {
      this.aboutServicePriceList.push({ name: '', price: '', image: '', description: '' });
    },
    async confirmRemoveServicePriceList(index) {
      const confirmed = window.confirm('Are you sure you want to delete this price list item?');
      if (confirmed) {
        this.removeServicePriceList(index);
      }
    },
    async removeServicePriceList(index) {
      const item = this.aboutServicePriceList[index];
      if (item._id) {
        try {
          await axios.delete(`${this.apiUrl}/pricelist/${item._id}`);
          this.aboutServicePriceList.splice(index, 1);
          alert('Pricelist entry deleted successfully!');
        } catch (error) {
          console.error('Failed to delete pricelist entry:', error);
        }
      } else {
        this.aboutServicePriceList.splice(index, 1);
      }
    },
    async handleServiceFileChange(index, event) {
      if (!event || !event.target || !event.target.files || event.target.files.length === 0) {
        console.error('Event or event.target.files is undefined or empty', event);
        return;
      }

      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('image', file);

      try {
        const response = await axios.post(`${this.apiUrl}/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        this.aboutServicePriceList[index].image = response.data.url;
      } catch (error) {
        console.error('Failed to upload image:', error);
      }
    },
  },
  mounted() {
    this.fetchServicePriceList();
  },
};
</script>

<style>
  h1 {
  text-align: center;
  padding: 20px;
  color: #fffffff5;
  }

  h3 {
  padding: 20px;
  text-align: center;
  color: #fffffff5;
  }

  .admin-pricelist-manager button {
  padding: 5px;
  border: none;
  border-radius: 3px;
  background-color: #E1C186;
  color: rgb(0, 0, 0);
  cursor: pointer;
  font-weight: bolder;
  text-transform: uppercase;
  }

  .admin-pricelist-manager button:hover {
  background-color: #D3A166;
  }

  .manage-pricelist input, select {
  padding: 8px;
  border: 1px solid #717171;
  border-radius: 3px;
  margin: 5px;
  color: #D3A166;
  }

  .manage-pricelist {
  padding: 15px;
  }

  .pricelist-box {
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  }

  .pricelist-details {
  margin: 10px;
  padding: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #717171;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.123);
  }

  .pricelist-details img {
  padding: 5px;
  width: 200px;
  border-radius: 10px;
  }

  .pricelist-details input {
  width: 300px;
  }

  .pricelist-buttons {
  padding: 15px;
  text-align: center;
  }
</style>