import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import AboutView from '../views/AboutView.vue';
import GalleryView from '../views/GalleryView.vue';
import ServicesView from '../views/ServicesView.vue';
import ContactView from '../views/ContactView.vue';
import BookingView from '../views/BookingView.vue';
import AdminView from '../views/AdminView.vue';
import PrivacyPolicy from '../views/PrivacyPolicy.vue';
import LoginView from '../views/LoginView.vue';

const isAuthenticated = () => {
  return localStorage.getItem('isAuthenticated') === 'true';
};

const routes = [
  { path: '/', component: HomeView },
  { path: '/about', component: AboutView },
  { path: '/gallery', component: GalleryView },
  { path: '/services', component: ServicesView },
  { path: '/contact', component: ContactView },
  { path: '/booking', component: BookingView },
  { 
    path: '/admin', 
    name: 'admin', 
    component: AdminView,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/login');
      }
    }
  },
  { path: '/privacy-policy', name: 'privacy-policy', component: PrivacyPolicy },
  { path: '/login', component: LoginView }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  }
});

export default router;
