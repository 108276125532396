<template>
  <div>
    <section id="gallery">
      <Gallery />
    </section>
  </div>
</template>

<script>
import Gallery from '../components/Gallery.vue';

export default {
  components: {
    Gallery,
  },
};
</script>

<style scoped>
section {
  padding: 4rem 2rem 2rem 2rem;
}
#gallery { 
  background-color: #3C3B39;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
</style>
