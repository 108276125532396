<template>
  <div class="admin-about-manager">
    <h1>Admin About Manager</h1>
    <div class="manage-about">
      <div class="about-header">
        <input v-model="aboutTitle" placeholder="About Title" />
        <textarea v-model="aboutText" placeholder="About Text"></textarea>
      </div>
      <div class="save-button">
        <button @click="saveAbout">Save</button>
      </div>

      <h1>Team Members</h1>
      <div class="about-buttons">
        <button @click="addMember">New Member</button>
      </div>
      <div class="members">
        <div v-for="(member, index) in aboutMembers" :key="index">
          <div class="member-details">
            <input v-model="member.name" placeholder="Name" />
            <input v-model="member.specialization" placeholder="Specialization" />
            <input v-model="member.experience" placeholder="Years of Experience" />
            <input type="file" @change="event => handleFileChange(index, event)" />
            <img v-if="member.imageUrl" :src="member.imageUrl" :alt="member.name" />
            <button @click="removeMember(index)">DELETE</button>
          </div>
        </div>
      </div>
      <div class="save-button">
        <button @click="saveAbout">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_BASE_URL,
      aboutTitle: '',
      aboutText: '',
      aboutMembers: [],
    };
  },
  methods: {
    async fetchAbout() {
      try {
        const response = await axios.get(`${this.apiUrl}/about`);
        const about = response.data[0];
        if (about) {
          this.aboutTitle = about.title;
          this.aboutText = about.text;
          this.aboutMembers = about.members;
        }
      } catch (error) {
        console.error('Failed to fetch about information:', error);
      }
    },
    async saveAbout() {
      try {
        await axios.post(`${this.apiUrl}/about`, {
          title: this.aboutTitle,
          text: this.aboutText,
          members: this.aboutMembers
        });
        alert('About information saved successfully!');
      } catch (error) {
        console.error('Failed to save about information:', error);
      }
    },
    addMember() {
      this.aboutMembers.push({ name: '', specialization: '', experience: '', imageUrl: '' });
    },
    async removeMember(index) {
      const isConfirmed = confirm('Are you sure you want to remove this member?');
      if (!isConfirmed) return;

      const memberId = this.aboutMembers[index]._id;
      try {
        await axios.delete(`${this.apiUrl}/about/members/${memberId}`);
        this.aboutMembers.splice(index, 1);
      } catch (error) {
        console.error('Failed to delete member:', error);
      }
    },
    async handleFileChange(index, event) {
      if (!event || !event.target || !event.target.files || event.target.files.length === 0) {
        console.error('Event or event.target.files is undefined or empty', event);
        return;
      }
      
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('image', file);

      try {
        const response = await axios.post(`${this.apiUrl}/about/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        this.aboutMembers[index].imageUrl = response.data.url;
      } catch (error) {
        console.error('Failed to upload image:', error);
      }
    },
  },
  mounted() {
    this.fetchAbout();
  }
};
</script>

<style>
h1 {
text-align: center;
padding: 20px;
color: #fffffff5;
}

h3 {
  padding: 20px;
  text-align: center;
  color: #fffffff5;
}
  
.manage-about button {
  padding: 5px;
  border: none;
  border-radius: 3px;
  background-color: #E1C186;
  color: rgb(0, 0, 0);
  cursor: pointer;
  font-weight: bolder;
  text-transform: uppercase;
}

.manage-about button:hover {
  background-color: #D3A166;
}

.manage-about input, select {
  padding: 8px;
  border: 1px solid #717171;
  border-radius: 3px;
  margin: 5px;
  color: #D3A166;
  text-align: center;
}

.manage-about textarea {
  height: 200px;
  border: 1px solid #717171;
  border-radius: 3px;
  text-align: center;
  margin: 5px;
  color: #D3A166;
}

.manage-about {
padding: 10px;
display: flex;
flex-direction: column;
}

.about-header {
display: flex;
flex-direction: column;
}

.members {
padding: 15px;
display: flex;
flex-wrap: wrap;
justify-content: center;
}

.about-buttons {
padding: 15px;
text-align: center;
}

.member-details {
margin: 10px;
padding: 15px;
display: flex;
align-items: center;
flex-direction: column;
border: 1px solid #717171;
border-radius: 5px;
background-color: rgba(255, 255, 255, 0.123);
}

.member-details input {
width: 300px;
}

.member-details img {
padding: 5px;
width: 200px;
border-radius: 10px;
}

.save-button {
  display: flex;
  justify-content: center;
}
</style>