<template>
  <section id="about" v-if="contentLoaded">
    <div class="about-content">
      <div class="about-text">
        <h2>{{ aboutTitle }}</h2>
        <br />
        <p>{{ aboutText }}</p>
      </div>
      <div class="team">
        <div
          v-for="(member, index) in teamMembers"
          :key="index"
          class="team-member"
          :style="{'animation-delay': index * 0.5 + 's'}"
        >
          <img :src="member.imageUrl" :alt="'Team Member ' + index">
          <h3>{{ member.name }}</h3>
          <p>Spezialisierung: {{ member.specialization }}</p>
          <p>Jahrelange Erfahrung: {{ member.experience }} years</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AboutView',
  data() {
    return {
      aboutTitle: '',
      aboutText: '',
      teamMembers: [],
      contentLoaded: false,
      apiUrl: process.env.VUE_APP_API_BASE_URL
    };
  },
  methods: {
    async fetchAbout() {
      try {
        const response = await axios.get(`${this.apiUrl}/about`);
        const about = response.data[0];
        if (about) {
          this.aboutTitle = about.title;
          this.aboutText = about.text;
          this.teamMembers = about.members;
          this.contentLoaded = true;
        }
      } catch (error) {
        console.error('Failed to fetch about information:', error);
      }
    }
  },
  mounted() {
    this.fetchAbout();
  }
};
</script>

<style scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

#about {
  padding: 2rem;
  background-color: #f5f5f5;
  color: #E1C186;
  min-height: 100vh;
}

.about-content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.about-text {
  margin-bottom: 2rem;
  font-size: 20px;
  animation: fadeIn 3s ease forwards;
}

.team {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-member {
  width: 100%;
  max-width: 300px;
  margin: 1rem;
  text-align: center;
  position: relative;
  opacity: 0;
  animation: fadeIn 2s ease forwards;
}

.team-member img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.team-member h3 {
  margin: 1rem 0 0.5rem;
  font-size: 1.2rem;
}

.team-member p {
  margin: 0.5rem 0;
}

h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #E1C186;
  font-weight: 700;
}

@media (min-width: 768px) {
  .team {
    flex-direction: row;
    justify-content: space-between;
  }

  .team-member {
    border-left: 1px solid #ddd;
    padding-left: 1rem;
  }

  .team-member:first-child {
    border-left: none;
  }

  h2 {
  font-size: 20px;
}
}
</style>
