<template>
  <div id="service">
    <h2>Dienstleistungen</h2>
    <div class="services-container">
      <div class="service-box" v-for="(service, index) in pricelist" :key="service._id">
        <img :src="service.image" :alt="`Service Image ${index}`" class="service-image" />
        <div class="service-info">
          <h3 class="service-name">{{ service.name }}</h3>
          <p class="service-description">{{ service.description }}</p>
          <p class="service-price">{{ service.price }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ServicesView',
  data() {
    return {
      pricelist: [],
      apiUrl: process.env.VUE_APP_API_BASE_URL,
    };
  },
  methods: {
    async fetchPriceList() {
      try {
        const response = await axios.get(`${this.apiUrl}/pricelist`);
        this.pricelist = response.data.map(service => ({
          ...service,
          image: service.image ? service.image : '', 
        }));
      } catch (error) {
        console.error('Failed to fetch Price List Details', error);
      }
    },
  },
  mounted() {
    this.fetchPriceList();
  },
};
</script>

<style scoped>
#service {
  padding: 2rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #E1C186;
    font-weight: 700;
  }

h3 {
  padding: 5px;
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding-top: 30px;
}

.service-box {
  background-color: rgba(255, 255, 255, 0.123);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 200px;
  text-align: center;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  color: #fff;
  border-radius: 5px;
}

.service-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.service-image {
  width: 100%;
  height: auto;
  border-radius: 3px;
}

.service-info {
  padding: 15px;
}

.service-name {
  font-size: 20px;
  font-weight: 600;
}

.service-description {
  padding: 5px;
  color: #D3A166;
}

.service-price {
  font-size: 20px;
  padding: 5px;
}

@media (max-width: 600px) {
  .service-name {
    font-size: 16px;
    padding: 5px;
    margin: 5px;
  }

  .service-price {
    font-size: 14px;
  }

  .service-box {
    max-width: max-content;
  }

  .service-info {
  padding: 5px;
  }

  h2 {
    font-size: 1.5rem;
  }
}
</style>
