<template>
    <section id="contact-info">
      <div class="background">
        <div class="overlay">
          <div class="info-container">
            <h1>Nehmen Sie mit uns Kontakt auf.</h1>
            <p class="subtext">
              Wir stehen Ihnen für Fragen persönlich, telefonisch oder auch per E-Mail gerne zur Verfügung.
            </p>
            <div class="columns">
              <div class="column">
                <h2>Öffnungszeiten</h2>
                <ul>
                  <li>Montag:  Geschlossen</li>
                  <li>Dienstag:  9:00 - 18:00</li>
                  <li>Mittwoch:  9:00 - 18:00</li>
                  <li>Donnerstag:  9:00 - 18:00</li>
                  <li>Freitag:  9:00 - 18:00</li>
                  <li>Samstag:  9:00 - 14:00</li>
                  <li>Sonntag:  Geschlossen</li>
                </ul>
              </div>
              <div class="column">
                <h2>Kontakt</h2>
                <p>Orchidee Beauty Studio</p><br/>
                <p>Hauptstraße 47, Iffezheim, Germany</p>
                <p>Orchideebeautystudio@gmail.com</p>
                <p>+49 1525 9503640</p><br/>
                <p>Für das Studio in Iffezheim.</p>
              </div>
            </div>
            <div class="center-button">
              <button class="btn"><a href="/booking">TERMIN BUCHEN</a></button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'ContactInfo'
  };
  </script>
  
  <style scoped>
  #contact-info {
    position: relative;
    min-height: 100vh;
    overflow: hidden;
  }
  
  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('@/assets/info_img2.png') no-repeat center center;
    background-size: cover;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(69 67 67 / 76%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .info-container {
    padding: 15px;
    border-radius: 8px;
    max-width: 800px;
    color: #fff;
    text-align: center;
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #E1C186;
  }
  
  .subtext {
    font-size: 1.2rem;
    color: #a6a5a5;
    margin-bottom: 2rem;
  }
  
  .columns {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
  
  .column {
    width: 45%;
    text-align: center;
  }
  
  h2 {
    font-size: 1.5rem;
    color: #D3A166;
    margin-bottom: 1rem;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }

  a {
    text-decoration: none;
    color: #000000;
  }
  
  li {
    margin-bottom: 0.5rem;
  }
  
  .center-button {
    text-align: center;
  }
  
  .btn {
    background-color: #E1C186;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .btn:hover {
    background-color: #D3A166;
  }
  
  /* Media query for small screens */
  @media (max-width: 600px) {
    .columns {
      flex-direction: column;
      align-items: center;
      font-size: 14px;
    }
  
    .column {
      width: 100%;
    }
  
    h1 {
      font-size: 20px;
      padding: 5px;
      margin: 10px;
    }

    h2 {
      margin: 10px;
      font-size: 1.5rem;
    }
  
    .subtext {
      font-size: 15px;
      margin-bottom: 10px;
    }
  }

  @media (max-width: 450px) {
    .columns {
      font-size: 12px;
    }
  
    .column {
      width: 100%;
      font-size: 10px;
    }
  
    h1 {
      font-size: 18px;
      padding: 3px;
      margin: 8px;
    }

    h2 {
      margin: 8px;
    }
  
    .subtext {
      font-size: 13px;
      margin-bottom: 8px;
    }

    .btn {
      font-size: 16px;
    }
  }
  </style>
  