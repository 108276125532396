<template>
  <nav :class="{ 'scrolled': isScrolled }">
    <div class="container">
      <div class="logo">
        <router-link to="/">
          <img :src="logo" alt="Orchidee Beauty Studio Logo" />
        </router-link>
      </div>

      <div class="menu-icon" @click="toggleMenu">
        <span v-if="!isMenuOpen">&#9776;</span>
        <span v-else>&times;</span>
      </div>

      <ul :class="{ 'nav-links': true, 'open': isMenuOpen }">
        <li @click="closeMenu">
          <router-link to="/">HOME</router-link>
        </li>
        <li @click="closeMenu">
          <router-link to="/about">ÜBER UNS</router-link>
        </li>
        <li @click="closeMenu">
          <router-link to="/gallery">GALERIE</router-link>
        </li>
        <li @click="closeMenu">
          <router-link to="/services">DIENSTLEISTUNGEN</router-link>
        </li>
        <li @click="closeMenu">
          <router-link to="/contact">KONTAKT</router-link>
        </li>
        <li @click="closeMenu">
          <router-link to="/booking">BUCHUNG</router-link>
        </li>

        <li class="social-media">
          <a href="https://instagram.com/Orchideebeautystudio" target="_blank" aria-label="Instagram">
            <i class="fab fa-instagram"></i>
          </a>
        </li>
        <li class="social-media">
          <a href="https://www.facebook.com/JoannaWetzelBeautyStylist" target="_blank" aria-label="Facebook">
            <i class="fab fa-facebook-f"></i>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import logo from '@/assets/logoSmallXS.png';

export default {
  name: 'NavBar',
  data() {
    return {
      isMenuOpen: false,
      isScrolled: false,
      logo: logo
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      if (this.isMenuOpen) {
        this.isMenuOpen = false;
      }
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 10;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>

<style scoped>
nav {
  position: fixed;
  top: 0;
  width: 100%;
  background: linear-gradient(to bottom, #000000, #3c3b3916);
  color: #ffffff;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  transition: background 0.3s, padding 0.3s;
  z-index: 1000;
  font-size: 25px;
}

nav.scrolled {
  background: linear-gradient(to bottom, #000000c1, #3c3b3916);
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.logo img {
  height: 50px;
  width: auto;
  cursor: pointer;
}

.menu-icon {
  display: none;
  font-size: 30px;
  cursor: pointer;
}

.nav-links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin: 0 10px;
}

.nav-links a {
  text-decoration: none;
  color: #d3d3d3;
  font-weight: 400;
  transition: transform 0.3s, color 0.3s;
}

.nav-links a:hover {
  transform: scale(1.1);
  color: #E1C186;
}

.social-media {
  margin: 0 10px;
}

.social-media a {
  color: #ffffff;
  font-size: 20px;
  transition: color 0.3s;
}

.social-media a:hover {
  color: #E1C186;
}

/* Media Queries for Tablet */
@media (max-width: 1080px) {
  .nav-links a {
    font-size: 18px;
  }
}

/* Media Queries for Mobile */
@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  .nav-links {
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    flex-direction: column;
    display: none;
    background-color: rgba(0, 0, 0, 0.497);
  }

  .nav-links.open {
    display: flex;
  }

  .nav-links li {
    margin: 10px;
  }
}
</style>
