<template>
  <div>
    <section id="contact">
      <Contact />
    </section>
  </div>
</template>

<script>
import Contact from '../components/Contact.vue';

export default {
  components: {
    Contact
  },
};
</script>

<style scoped>
#contact { 
  background-color: #3C3B39; 
}
</style>
