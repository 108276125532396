<template>
  <section id="gallery">
    <h2>Galerie</h2>
    <div class="gallery-container">
      <div v-for="(image, index) in allImages" :key="index" class="gallery-item">
        <img :src="image.url" :alt="image.description || 'Gallery Image'">
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  name: 'GalleryView',
  data() {
    return {
      allImages: [],
      apiUrl: process.env.VUE_APP_API_BASE_URL
    };
  },
  methods: {
    fetchImages() {
      axios.get(`${this.apiUrl}/gallery`)
        .then(response => {
          this.allImages = response.data.map(image => ({
            ...image,
            url: image.url 
          }));
        })
        .catch(error => {
          console.error("There was an error fetching the images:", error);
        });
    }
  },
  created() {
    this.fetchImages();
  }
};
</script>

<style scoped>
#gallery {
  max-width: 1240px;
  min-height: 100vh;
}

.gallery-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
}

h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #E1C186;
  font-weight: 700;
}

.gallery-item {
  flex: 1 1 calc(33.333% - 1rem);
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease-in-out;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  display: block;
}

.gallery-item:hover {
  transform: scale(1.05);
}

.load-more-button {
  margin-top: 2rem;
  margin-left: 3px;
  background-color: #E1C186;
  font-weight: bold;
}

button {
  background-color: #E1C186;
  font-weight: bold;
}

.hide-images-button {
  margin-top: 1rem;
  margin-left: 3px;
  background-color: #E1C186;
  font-weight: bold;
}

button:hover {
  background-color: #D3A166;
}

@media (max-width: 600px) {
  #gallery {
  padding: 20px;
  margin-top: 30px;
}
.gallery-container {
    display: flex;
    flex-direction: column-reverse;
}

h2 {
    font-size: 1.5rem;
  }
}

@media (max-width: 420px) {
  #gallery {
  padding: 10px;
}
}
</style>
