<template>
  <div class="booking">
    <h2>Reservierung</h2>
    <div class="calendar">
      <div class="calendar-body">
        <label for="serviceSelect">Verfügbare Dienstleistungen:</label>
        <select id="serviceSelect" v-model="selectedServiceId" @change="updateAvailableDates">
          <option disabled value="">Dienstleistung auswählen</option>
          <option v-for="service in filteredServices" :key="service._id" :value="service._id">
            {{ service.name }}
          </option>
        </select>
        <label for="dateSelect">Verfügbares Datum:</label>
        <select id="dateSelect" v-model="selectedDate" @change="updateAvailableTimes">
          <option disabled value="">Datum auswählen</option>
          <option v-for="(day, index) in daysInRange" :key="index" :value="day.date">
            {{ formatDate(day.date) }}
          </option>
        </select>
        <label for="timeSelect">Verfügbare Uhrzeit:</label>
        <select id="timeSelect" v-model="selectedTime" @change="updateReservationForm">
          <option disabled value="">Uhrzeit auswählen</option>
          <option v-for="hour in availableHours" :key="hour" :value="hour">
            {{ hour }}
          </option>
        </select>
      </div>
    </div>
    <h2 v-if="showReservationForm">RESERVIERUNG BESTÄTIGEN</h2>
    <div class="reservation-form" v-if="showReservationForm">
      <input v-model="reservationDate" placeholder="Date (DD/MM/YYYY)" readonly />
      <input v-model="reservationTime" placeholder="Time (HH:MM)" readonly />
      <input
        v-model="reservationUser"
        placeholder="Vollständige Name"
        type="text"
        autocomplete="name"
      />
      <input
        v-model="reservationPhone"
        placeholder="Telefon/Handy"
        type="tel"
        autocomplete="tel"
      />
      <input
        v-model="reservationEmail"
        placeholder="E-mail"
        type="email"
        autocomplete="email"
      />
      <input v-model="additionalInfo" placeholder="Additional Information" />
      <div class="buttons">
        <button @click="confirmReservation">SENDEN</button>
        <button @click="cancelReservation">ABBRECHEN</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_BASE_URL,
      daysInRange: this.getDaysInRange(),
      hours: this.generateHours(),
      availableHours: [],
      reservations: [],
      reservationDate: '',
      reservationTime: '',
      reservationUser: '',
      reservationPhone: '',
      reservationEmail: '',
      selectedServiceId: '',
      serviceName: '',
      additionalInfo: '',
      showReservationForm: false,
      selectedDate: '',
      selectedTime: '',
      services: [],
      serviceDuration: 0,
    };
  },
  computed: {
    selectedServiceName() {
      const service = this.services.find(service => service._id === this.selectedServiceId);
      return service ? service.name : '';
    },
    filteredServices() {
      return this.services.filter(service => service.name !== 'Holiday' && service.name !== 'HalfDay');
    }
  },
  methods: {
    async fetchServices() {
      try {
        const response = await axios.get(`${this.apiUrl}/services`);
        this.services = response.data;
      } catch (error) {
        console.error('Failed to fetch services:', error);
      }
    },
    generateHours() {
      const hours = [];
      for (let h = 9; h <= 17; h++) {
        hours.push(`${h < 10 ? '0' : ''}${h}:00`);
        if (h < 17) {
          hours.push(`${h < 10 ? '0' : ''}${h}:30`);
        }
      }
      hours.push('17:30');
      return hours;
    },
    getDaysInRange() {
      const days = [];
      const today = new Date();
      let count = 0;

      for (let i = 1; count < 21; i++) {
        const date = new Date(today);
        date.setDate(today.getDate() + i);

        if (date.getDay() !== 0 && date.getDay() !== 1) {
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const day = date.getDate();
          days.push({
            date: `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`,
          });
          count++;
        }
      }
      return days;
    },
    formatDate(dateString) {
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      const date = new Date(dateString);
      return date.toLocaleDateString('de-DE', options);
    },
    async fetchReservations() {
    try {
        const response = await axios.get(`${this.apiUrl}/bookings/minimal`);
        this.reservations = response.data;
    } catch (error) {
        console.error('Failed to fetch reservations:', error);
    }
    },
    updateAvailableDates() {
      if (this.selectedServiceId) {
        const selectedService = this.services.find(service => service._id === this.selectedServiceId);
        this.serviceDuration = selectedService ? selectedService.duration : 0;
        this.updateAvailableTimes();
      }
    },
    async updateAvailableTimes() {
      if (this.selectedDate) {
        const endHourLimit = this.calculateEndHourLimit();

        this.availableHours = this.hours.filter(hour => {
          return hour <= endHourLimit && !this.isBooked(this.selectedDate, hour);
        });
      } else {
        this.availableHours = [];
      }
    },

    calculateEndHourLimit() {
      const workEndHour = 18;
      const durationInHours = this.serviceDuration / 60;

      const endHour = workEndHour - durationInHours;

      const isWholeHour = this.serviceDuration % 60 === 0;

      const endMinute = isWholeHour ? 0 : 30;

      return `${Math.floor(endHour).toString().padStart(2, '0')}:${endMinute.toString().padStart(2, '0')}`;
    },
    isBooked(date, hour) {
        const endTime = this.calculateEndTime(hour, this.serviceDuration);
        const startTime = new Date(`${date}T${hour}:00`);
        const endTimeDate = new Date(`${date}T${endTime}:00`);

        return this.reservations.some(r => {
            const reservationDate = new Date(r.date);
            const reservationStartTime = new Date(reservationDate.setHours(...r.time.split(':')));
            const reservationEndTime = new Date(reservationDate.setHours(...this.calculateEndTime(r.time, r.duration).split(':')));

            const isOverlap = reservationStartTime < endTimeDate && reservationEndTime > startTime;

            return isOverlap && r.isBooked;
        });
    },
    async confirmReservation() {
      if (!this.reservationUser || !this.reservationPhone || !this.reservationEmail || !this.selectedServiceId) {
        alert('Name, Telefonnummer und Dienstleistungen sind erforderlich.');
        return;
      }

      if (confirm(`Bestätigen Sie Reservierung für ${this.reservationDate} um ${this.reservationTime} für Dienstleistung ${this.selectedServiceName}?`)) {
        try {
          const response = await axios.post(`${this.apiUrl}/bookings`, {
            date: this.reservationDate,
            time: this.reservationTime,
            user: this.reservationUser,
            phone: this.reservationPhone,
            email: this.reservationEmail,
            service: this.selectedServiceId,
            serviceName: this.selectedServiceName,
            additionalInfo: this.additionalInfo,
            duration: this.serviceDuration,
          });

          if (response.status === 201) {
            this.fetchReservations();
            this.showReservationForm = false;
            this.resetForm();
            
            alert('Ihre Anfrage zur Terminbuchung wurde versendet. Wir werden uns bald mit Ihnen kontaktieren.');
          } else {
            alert('Der Slot konnte nicht gebucht werden. Möglicherweise ist er bereits vergeben.');
          }
        } catch (error) {
          console.error('Failed to submit reservation:', error);
          alert('An error occurred while trying to book the slot. Please try again.');
        }
      }
    },
    cancelReservation() {
      this.showReservationForm = false;
      this.resetForm();
    },
    resetForm() {
      this.reservationDate = '';
      this.reservationTime = '';
      this.reservationUser = '';
      this.reservationPhone = '';
      this.reservationEmail = '';
      this.selectedServiceId = '';
      this.additionalInfo = '';
      this.selectedDate = '';
      this.selectedTime = '';
      this.serviceDuration = 0;
    },
    calculateEndTime(startTime, duration) {
      const [hours, minutes] = startTime.split(':').map(Number);
      const start = new Date();
      start.setHours(hours, minutes, 0, 0);

      const end = new Date(start.getTime() + duration * 60000);
      const endHours = end.getHours().toString().padStart(2, '0');
      const endMinutes = end.getMinutes().toString().padStart(2, '0');

      return `${endHours}:${endMinutes}`;
    },
    updateReservationForm() {
      if (this.selectedTime && this.selectedDate) {
        this.reservationDate = this.selectedDate;
        this.reservationTime = this.selectedTime;
        this.showReservationForm = true;
      } else {
        this.showReservationForm = false;
      }
    }
  },
  mounted() {
    this.fetchReservations();
    this.fetchServices();
  }
};
</script>

<style scoped>
.booking {
  margin-top: 50px;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.123);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 800px;
  max-height: 830px;
}

.calendar {
  margin-bottom: 20px;
}

h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #E1C186;
  font-weight: 700;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.calendar-body {
  display: flex;
  flex-wrap: wrap;
  color: #E1C186;
  justify-content: center;
  align-content: stretch;
  flex-direction: column;
}

.day {
  border: 1px solid #736b6b;
  padding: 10px;
  margin: 5px;
  box-sizing: border-box;
  display: inline-grid;
  justify-items: center
}

button {
  padding: 2px 6px;
  margin: 2px;
  border: none;
  border-radius: 3px;
  background-color: #E1C186;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
  width: 130px;
  align-self: center;
  font-weight: bolder;
}

button:hover {
  background-color: #D3A166;
}

input, select {
  margin-right: 10px;
  padding: 8px;
  border: 1px solid #717171;
  border-radius: 3px;
  color: #E1C186;
  appearance: auto; 
}

.reservation-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.buttons {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 600px) {
  .form-buttons {
    flex-direction: column;
  }

  button {
    margin: 5px 0;
  }

  h2 {
    font-size: 1.5rem;
  }
}
</style>
