<template>
  <div class="home">
    <!-- Hero Section -->
    <section id="welcome">
      <Welcome />
    </section>

    <!-- About Section -->
    <section id="about">
      <About />
    </section>

    <!-- Services Section -->
    <section id="services">
      <Services />
    </section>

    <!-- Information Section -->
    <section id="Information">
      <Information />
    </section>

    <!-- Contact Section -->
    <section id="contact">
      <Contact />
    </section>

  </div>
</template>

<script>
import Welcome from '../components/Welcome.vue';
import About from '../components/About.vue'
import Services from '../components/Services.vue';
import Information from '../components/Information.vue';
import Contact from '../components/Contact.vue';

export default {
  name: 'HomeView',
  components: {
    Welcome,
    About,
    Services,
    Information,
    Contact
  }
};
</script>

<style scoped>
   
#welcome { background-color: #3C3B39;}
#about { background-color: #302E2F; }
#services { background-color: #3C3B39; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);}
#contact { background-color: #302E2F; }
</style>