<template>
    <div>
      <section id="about">
        <About />
      </section>
    </div>
  </template>
  
  <script>
  import About from '../components/About.vue';
  
  export default {
    components: {
      About
    }
  };
  </script>
  
  <style scoped>
  section {
    padding: 4rem 2rem 2rem 2rem;
  }
  #about { background-color: #3C3B39; min-height: 80vh; }
  </style>
  