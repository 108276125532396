<template>
  <div class="admin-service">
    <h1>Manage Services</h1>
    <input v-model="newServiceName" placeholder="New Service Name" />
    <select v-model="newServiceDuration">
      <option v-for="duration in durations" :value="duration.value" :key="duration.value">
        {{ duration.label }}
      </option>
    </select>
    <button @click="addService">Add Service</button>
    <div class="services-list">
      <ul>
        <li v-for="service in services" :key="service._id">
          <div class="service-box">
            <span>{{ service.name }} : {{ formatDuration(service.duration) }}</span>
            <button 
              v-if="service.name !== 'Holiday' && service.name !== 'HalfDay'"
              @click="confirmDeleteService(service._id)">Delete</button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_BASE_URL,
      services: [],
      newServiceName: '',
      newServiceDuration: 30,
      durations: [
        { label: '30 minutes', value: 30 },
        { label: '1 hour', value: 60 },
        { label: '1.5 hours', value: 90 },
        { label: '2 hours', value: 120 },
        { label: '2.5 hours', value: 150 },
        { label: '3 hours', value: 180 },
        { label: '4 hours', value: 240 },
        { label: '5 hours', value: 300 },
        { label: '6 hours', value: 360 },
        { label: '7 hours', value: 420 },
        { label: '8 hours', value: 480 },
      ],
    };
  },
  methods: {
    async fetchServices() {
      try {
        const response = await axios.get(`${this.apiUrl}/services`);
        this.services = response.data;

        if (!this.services.some(service => service.name === 'Holiday')) {
          await this.addHolidayService();
        }

        if (!this.services.some(service => service.name === 'HalfDay')) {
          await this.addHalfDayService();
        }
      } catch (error) {
        console.error('Failed to fetch services:', error);
      }
    },

    async addHolidayService() {
      try {
        const response = await axios.post(`${this.apiUrl}/services`, {
          name: 'Holiday',
          duration: 520,
        });
        this.services.push(response.data);
      } catch (error) {
        console.error('Failed to add Holiday service:', error);
      }
    },

    async addHalfDayService() {
      try {
        const response = await axios.post(`${this.apiUrl}/services`, {
          name: 'HalfDay',
          duration: 240,
        });
        this.services.push(response.data);
      } catch (error) {
        console.error('Failed to add HalfDay service:', error);
      }
    },

    async addService() {
      try {
        const response = await axios.post(`${this.apiUrl}/services`, {
          name: this.newServiceName,
          duration: this.newServiceDuration,
        });
        this.services.push(response.data);
        this.newServiceName = '';
        this.newServiceDuration = 30;
        alert('Service has been successfully added.');
      } catch (error) {
        console.error('Failed to add service:', error);
      }
    },

    async deleteService(id) {
      try {
        await axios.delete(`${this.apiUrl}/services/${id}`);
        this.services = this.services.filter(service => service._id !== id);
        alert('Service has been successfully deleted.');
      } catch (error) {
        console.error('Failed to delete service:', error);
      }
    },

    confirmDeleteService(id) {
      if (confirm('Are you sure you want to delete this service?')) {
        this.deleteService(id);
      }
    },

    formatDuration(duration) {
      return `${duration} minute${duration !== 1 ? 's' : ''}`;
    }
  },

  mounted() {
    this.fetchServices();
  }
};
</script>

<style scoped>
  h1 {
    text-align: center;
    padding: 20px;
    color: #fffffff5;
  }

  input, select {
    padding: 8px;
    border: 1px solid #717171;
    border-radius: 3px;
    margin: 5px;
    color: #D3A166;
  }

  button {
    padding: 5px;
    border: none;
    border-radius: 3px;
    background-color: #E1C186;
    color: rgb(0, 0, 0);
    cursor: pointer;
    font-weight: bolder;
    text-transform: uppercase;
  }

  button:hover {
    background-color: #D3A166;
  }

  .services-list {
    margin-top: 20px;
  }

  .service-box {
    border: 1px solid #717171;
    padding: 10px;
    margin: 5px;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.123);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .service-box span {
    margin-right: 10px;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    margin: 5px 0;
    color: #D3A166;
  }
</style>
