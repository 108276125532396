<template>
  <div class="manage-gallery">
    <h1>Admin Gallery Manager</h1>
    <input type="file" @change="onFileChange" />
    <button @click="uploadImage">Save</button>
    <div class="gallery-list">
      <div v-for="(image, index) in gallery" :key="image._id" class="gallery-item">
        <img :src="image.url" :alt="'Image ' + index" />
        <button @click="deleteImage(image._id)">Delete</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      gallery: [],
      selectedFile: null,
      apiUrl: process.env.VUE_APP_API_BASE_URL
    };
  },
  methods: {
    onFileChange(event) {
      if (!event || !event.target) {
        console.error('Event or event.target is undefined', event);
        return;
      }
      this.selectedFile = event.target.files[0];
    },
    async uploadImage() {
      if (!this.selectedFile) {
        alert('Please select a file first.');
        return;
      }

      const formData = new FormData();
      formData.append('image', this.selectedFile);

      try {
        const response = await axios.post(`${this.apiUrl}/gallery`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        this.gallery.push(response.data);
        this.selectedFile = null;
        alert('Image uploaded successfully!');
      } catch (error) {
        console.error('Failed to upload image:', error);
        alert('Failed to upload image. Please try again.');
      }
    },
    async fetchGallery() {
      try {
        const response = await axios.get(`${this.apiUrl}/gallery`);
        this.gallery = response.data;
      } catch (error) {
        console.error('Failed to fetch gallery:', error);
      }
    },
    async deleteImage(id) {
      if (!confirm('Are you sure you want to delete this image?')) {
        return;
      }

      try {
        await axios.delete(`${this.apiUrl}/gallery/${id}`);
        this.gallery = this.gallery.filter(image => image._id !== id);
        alert('Image deleted successfully!');
      } catch (error) {
        console.error('Failed to delete image:', error);
        alert('Failed to delete image. Please try again.');
      }
    }
  },
  mounted() {
    this.fetchGallery();
  }
};
</script>
  
<style scoped>
  h1 {
  text-align: center;
  padding: 20px;
  color: #fffffff5;
  }

  h3 {
    padding: 20px;
    text-align: center;
    color: #fffffff5;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }

  th, td {
    border: 1px solid #717171;
    padding: 8px;
    color: #D3A166;
  }

  th {
    background-color: #3C3B39;
    text-align: center;
    text-transform: uppercase;
    color: #fffffff5;
  }
    
  button {
    padding: 5px;
    border: none;
    border-radius: 3px;
    background-color: #E1C186;
    color: rgb(0, 0, 0);
    cursor: pointer;
    font-weight: bolder;
    text-transform: uppercase;
  }

  button:hover {
    background-color: #D3A166;
  }

  input, select {
    padding: 8px;
    border: 1px solid #717171;
    border-radius: 3px;
    margin: 5px;
    color: #D3A166;
    max-width: 400px;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    margin: 5px 0;
  }

  textarea {
    height: 200px;
    border: 1px solid #717171;
    border-radius: 3px;
    text-align: center;
    margin: 5px;
    color: #D3A166;
  }

  .manage-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gallery-list {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.gallery-item {
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: center;
}

.gallery-list img {
  width: 130px;
  border-radius: 5px;
}
</style>
  