<template>
  <div id="app">
    <!-- <section id="banner"><Banner /></section> -->
    <NavBar />
    <CookieConsent />
    <router-view />
    <Footer />
  </div>
</template>

<script>
// import Banner from './components/Banner.vue';
import NavBar from './components/NavBar.vue';
import Footer from './components/Footer.vue';
import CookieConsent from './components/CookieConsent.vue';

export default {
  name: 'App',
  components: {
    // Banner,
    CookieConsent,
    NavBar,
    Footer
  }
};
</script>

<style>
h1 {
  text-align: center;
  margin: 2rem;
  font-size: 30px;
}

h2 {
  text-align: center;
  color: #D3A166;
  font-weight: 400;
  font-size: 27px;
  margin: 1rem;
}

button {
  padding: 2px 6px;
  margin: 2px;
  border: none;
  border-radius: 3px;
  background-color: #E1C186;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
  width: 130px;
  align-self: center;
  color: #000000;
  font-weight: bolder;
}

button:hover {
  background-color: #D3A166;
}

@media (max-width: 425px) {
  h1 {font-size: 25px;}
  h2 {font-size: 20px;}
  p {font-size: 15px;}
  }
</style>
