<template>
  <div>

    <section id="adminBooking">
      <AdminBooking />
    </section>

    <section id="adminService">
      <AdminService />
    </section>

    <section id="adminWelcome">
      <AdminWelcome />
    </section>

    <section id="adminGallery">
      <AdminGallery />
    </section>

    <section id="adminAbout">
      <AdminAbout />
    </section>

    <section id="adminPriceList">
      <AdminPriceList />
    </section>

  </div>
</template>
  
<script>
  import AdminBooking from '../components/AdminBooking.vue';
  import AdminService from '../components/AdminService.vue';
  import AdminWelcome from '../components/AdminWelcome.vue';
  import AdminGallery from '../components/AdminGallery.vue';
  import AdminAbout from '../components/AdminAbout.vue';
  import AdminPriceList from '../components/AdminPriceList.vue';
  
  export default {
    components: {
      AdminBooking,
      AdminService,
      AdminWelcome,
      AdminGallery,
      AdminAbout,
      AdminPriceList
    }
  };
</script>
  
<style scoped>
  section {
    padding: 4rem 2rem 2rem 2rem;
    box-shadow: 0 0 20px #7c603fb0;
  }

  section:last-of-type {
    border-bottom: none;
  }

  #adminBooking { background-color: #3C3B39; }
  #adminService { background-color: #3C3B39; }
  #adminWelcome { background-color: #3C3B39; }
  #adminGallery { background-color: #3C3B39; display: flex; justify-content: center;}
  #adminAbout { background-color: #3C3B39; }
  #adminPriceList { background-color: #3C3B39; }
</style>
